<template>
    <div class="pop-toast-wrap">
        <div class="pop-toast">
            <div class="toast-icon">
                <img v-if="type == 1" src="@/assets/info.png" alt="">
                <img v-else-if=" type == 2" src="@/assets/error.png" alt="">
                <img v-else src="@/assets/success-filling.png" alt="">
            </div>
            <div>
                {{msg}}
            </div>
        </div>
    </div>
</template>

<script>
/**
 * 目前仅用于ToastAPI
 */
import { defineComponent } from 'vue'
export default defineComponent({
    props: {
        msg: String,
        type: {
            type: Number,
            default: 1
        }
    }
})
</script>

<style>
.toast-icon img{
    width: 24px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
}
.pop-toast {
    display: inline-block;
    align-items: center;
    background-color: rgba(0,0,0,.7);
    height: 22px;
    color: #ccc;
    border-radius: 2px;
    padding: 8px;
    margin: 0 auto;
    z-index: 66666666;
}
.pop-toast>div {
    float: left;
}
</style>