<template>
    <div class="invite">
        <div class="top-bar">
            <img src="@/assets/关闭.png" alt="" @click=" $emit('updateShow')">
        </div>
        <div class="block">
            <div class="title">{{ $t('Pool data') }}</div>
            <div class="data">
                <ul>
                    <li>
                        <div class="item-name">{{ $t('level 1 Total output') }}</div>
                        <div class="item-value">{{ data.level1 ? data.level1 : 0 }} USDT</div>
                    </li>
                    <li>
                        <div class="item-name">{{ $t('level 2 Total output') }}</div>
                        <div class="item-value">{{ data.level2 ? data.level2 : 0 }} USDT</div>
                    </li>
                    <li>
                        <div class="item-name">{{ $t('level 3 Total output') }}</div>
                        <div class="item-value">{{ data.level3 ? data.level3 : 0 }} USDT</div>
                    </li>
                    <li>
                        <div class="item-name">{{ $t('Team Revenue') }}</div>
                        <div class="item-value">{{ data.total ? data.total : 0 }} USDT</div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="share shadow">
            <p class="block-name">{{ $t('My share link') }}</p>
            <div class="referrer shadow flex">
                <input style="color: #aaa;" type="text" v-model="inviteUrl" readonly id="inviteUrl2">
                <button class="copy-url2" data-clipboard-target="#inviteUrl2">{{ $t('Copy') }}</button>
            </div>
            <p class="block-desc">{{ $t('share hashtable desc') }}</p>

            <ul class="share-table">
                <li class="thead">
                    <div>Level</div>
                    <div style="border-right: 1px solid #bbb;">Interval</div>
                    <div>Rebate</div>
                </li>
                <li v-for="item in data.levelTables" :key="item[0]">
                    <div>{{ item.level }}</div>
                    <div>{{ item.interval }}</div>
                    <div v-html="item.rebate"></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ClipBoard from 'clipboard'
import { defineComponent, computed, onUnmounted } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
    setup() {
        let store = useStore();

        return {
            chainId: computed(() => {
                return store.chainId
            }),
            myAddress: computed(() => {
                return store.myAddress
            })
        }

    },
    data() {
        return {
            data: {
                level1: "",
                level2: "",
                level3: "",
                total: "",
                levelTables: [
                    ['VIP1', '9-499', "Lev1: 8.00%"],
                    ['VIP2', '500-2999', 'Lev1: 8.00%'],
                    ['VIP3', '3000-14999', 'Lev1: 8.00%'],
                ]
            },
            inviteUrl: '',

        }
    },
    async mounted() {
        let rebate = [
            'Lev1:8.00%<br>Lev2:8.00%',
            'Lev1:8.00%<br>Lev2:8.00%',
            'Lev1:8.00%<br>Lev2:8.00%',
            'Lev1:8.00%<br>Lev2:8.00%',
            'Lev1:8.00%<br>Lev2:8.00%',
            'Lev1:8.00%<br>Lev2:8.00%',
            'Lev1:8.00%<br>Lev2:8.00%'
        ];

        let clip = new ClipBoard('.copy-url2')
        onUnmounted(() => {
            clip.destroy()
        })

        clip.on('success', () => {
            this.$toast(this.$t('Successful'), 3)
        })

        let response = (await this.HTTPv1('api/information/team/' + this.myAddress))
        if (response.status !== 200) {
            return //请求失败
        }
        if (response.data.data) {
            this.data = response.data.data;
            this.data.levelTables.length ? this.data.levelTables.forEach((item, index) => {
                // item.rebate = rebate[index];
                item.interval = item.interval.replaceAll('.00000', '')
            }) : null;
        }


        this.inviteUrl = "https://" + this.generateRandomString(6) + "." + document.domain.split('.').slice(-2).join('.') + (this.data.inviteCode ? '/?code=' + this.data.inviteCode : '');
    },
    methods: {
        generateRandomString(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }

            return result;
        }
    }
})
</script>

<style scoped>
.invite button {
    background-color: #ff8517;
}

.share-table {
    border: 1px solid #bbb;
    margin: 10px;
    color: #999;
}

.share-table li {
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
}

.share-table li div {
    display: flex;
    flex: 1;
    text-align: center;
    padding: 8px;
    width: 33.3%;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
    align-content: center;
    word-break: break-all;
}

.share-table li>div:not(:last-of-type) {
    border-right: 1px solid #bbb;
}


.share-table .thead div {
    line-height: 18px;
    font-weight: bold;
    background-color: #f2f2f2;
}

.share-table li:not(.thead) div {
    min-height: 48px;
}

.share-table li:not(.thead) {
    border-top: 1px solid #bbb;
}

.share {
    padding-bottom: 5px;
}
</style>

<style>
@media screen and (min-width: 700px) {
    div.block .title {
        font-size: 36px;
        padding: 0 24px;
    }

    div.block .title::before {
        width: 7px;
        height: 36px;
        margin-top: 6px;
    }

    .block .data div.item-name,
    .block .data div.item-value {
        font-size: 28px;
    }
}

.block {
    background-color: #fff;
    border-radius: 15px;
    padding-top: 24px;
    margin-bottom: 30px;
    box-shadow: 6px 12px 20px 0 rgb(0 0 0 / 5%);
}

.block .title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    padding: 0 15px;
}

.block .title::before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 4px;
    height: 20px;
    background: linear-gradient(1turn, rgba(90, 71, 217, .09), #71a8e0);
    border-radius: 4px;
}

.block .data {
    padding: 5px 15px;
}

.block .data li {
    display: flex;
    display: -webkit-flex;
    margin: 10px 0;
}

.block .data li>div {
    width: 50%;
}

.block .data .item-name {
    color: #666;
}

.block .data .item-value {
    text-align: right;
    color: #222;
    font-size: 16px;
    font-weight: bold;
}

.block .data .item-value.blue {
    color: #2082fe;
}

.invite {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 6666;
    padding: 16px;
    box-sizing: border-box;
    overflow-y: auto;
}

.top-bar {
    text-align: right;
    padding: 10px;
}

.top-bar img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.shadow {
    box-shadow: 6px 12px 20px 0 rgb(0 0 0 / 5%);
}

.block-desc {
    color: #a1a1b3;
    padding: 10px;
    line-height: 24px;
    margin-top: 10px;
    line-height: 24px;
}

.block-name {
    padding: 15px;
    font-size: 17px;
    color: #888;
}

.referrer {
    align-items: center;
    padding: 15px 26px;
    width: calc(100% - 20px);
    margin: 0 auto;
    box-sizing: border-box;
}

.referrer input {
    flex: 1;
    margin-right: 10px;
    height: 26px;
    vertical-align: middle;
}

.referrer button {
    width: 80px !important;
    margin: 0;
    padding: 0;
    min-width: 80px;
}

input {
    outline: none;
    border: none;
}
</style>