import { createVNode, render } from 'vue'
import Toast from './index.vue'

/**
 * API形式调用toast提示，在main.js中$toast方法挂载到了全局，所有组件实例的this都可以访问$toast
 * @param {String} msg 提示的内容
 * @param {Number} type 图标类型，,1=信息，2=失败，3=成功
 * @param {Number} duration 可选，提示显示的时间，单位：毫秒
 * @returns {Object} vnode实例
 */
export default function(msg,type,duration=2000){
    let mounteNode = document.createElement('div');

    let app = createVNode(Toast,{type,msg})

    render(app,mounteNode)

    let wrap = document.querySelector('.pop-list')
    wrap.insertBefore(mounteNode,wrap.firstChild)

    setTimeout(() => {
        wrap.removeChild(mounteNode)
    }, duration);
    return app
}