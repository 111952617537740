function VueDraggable(DOMEl,clickCallback,width,height){
    this._el = DOMEl;
    this.elWidth = width;
    this.elHeight = height;
    this.moving = false;
    this.moveDistance = 0;
    this._el.style.position = 'fixed';

    this.mouseDownEvent = (ev)=>{
        this.moveDistance = 0;
        this.moving = true;
    }
    this.mouseMoveEvent = (ev)=>{
        if(this.moving) {
            let _left = ev.touches[0].clientX-(this.elWidth/2),
                _top = ev.touches[0].clientY-(this.elHeight/2)
            if(_left>0 && _top>0 && window.innerWidth - (this.elWidth+_left)>=0 && window.innerHeight - (this.elHeight+_top)>=0){
                this.moveDistance += 1;
                this._el.style.left = _left+'px'
                this._el.style.top = _top+'px'
            }
        }
        ev.preventDefault()
    }
    this.mouseUpEvent = (ev)=>{
        this.moving = false;
        if(clickCallback && this.moveDistance === 0){
            clickCallback(ev)
        }
    }
    // DOMEl.addEventListener('mousedown',this.mouseDownEvent)
    // DOMEl.addEventListener('mousemove',this.mouseMoveEvent)
    // DOMEl.addEventListener('mouseup',this.mouseUpEvent)
    DOMEl.addEventListener('touchstart',this.mouseDownEvent)
    DOMEl.addEventListener('touchmove',this.mouseMoveEvent)
    DOMEl.addEventListener('touchend',this.mouseUpEvent)
}

VueDraggable.prototype.destory = function(){
    this._el.removeEventListener('touchstart',this.mouseDownEvent)
    this._el.removeEventListener('touchmove',this.mouseMoveEvent)
    this._el.removeEventListener('touchend',this.mouseUpEvent)
}
export default VueDraggable;