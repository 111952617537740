<template>
    <div class="notice">
        <div class="notice-wrap">
            <div class="title">
                {{title}}
                <img src="@/assets/close_white.png" alt="" @click=" $emit('close')">
            </div>
            <div class="content" v-html="msg">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title','msg'],
    emits: ['close']
}
</script>

<style scoped>
    .notice{
        position: fixed;
        display: flex;
        display: -webkit-flex;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        flex-wrap: wrap;
        align-content: center;
    }
    .notice-wrap {
        width: 90%;
        background-color: white;
        border-radius: 13px;
        overflow: hidden;
        min-height: 200px;
        margin: auto;
        border: 1px solid #777;
    }
    .title {
        position: relative;
        background-color: #F59A23;
        color: white;
        padding: 10px 0;
        text-align: center;
    }
    .title img {
        position: absolute;
        right: 15px;
        top: 10px;
        width: 20px;
    }
    .content {
        padding: 8px;
        height: 100%;
    }
</style>