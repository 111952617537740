<!-- 折叠面版 -->
<template>
    <div class="collapse" @click="showContent = !showContent">
        <div class="title">
            {{title}}
            <div class="arrow">
                <img src="@/assets/arrow_up.svg" alt="" :class=" {'rotate-180':!showContent} ">
            </div>
        </div>
        <transition name="desc">
            <div v-if="showContent" class="collapse-content">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    props: ['title'],
    setup(){
        let showContent = ref(false);
        return {showContent}
    }
}
</script>

<style>
.desc-enter-from,
.desc-leave-to {
    max-height: 0;
    opacity: 0;
}

.desc-enter-active,
.desc-leave-active {
    transition: .5s;
}

.desc-enter-to,
.desc-leave-from {
    max-height: 500px;
    opacity: 1;
}
</style>

<style scoped>
@media screen and (min-width: 700px){
    div.title {
        font-size: 36px;
    }
    div.collapse-content {
        line-height: 30px;
        font-size: 26px;
    }
}
.rotate-180 {
    transform: rotate(180deg);
}
.collapse {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 16px;
}
.title {
    font-weight: bold;
    font-size: 16.6px;
    padding: 9px;
}
.collapse-content {
    color: #a1a1b3;
    padding: 10px;
    line-height: 25px;
    font-size: 16.6px;
    overflow: hidden;
    box-sizing: border-box;
}
.arrow {
    position: absolute;
    top: 12px;
    right: 15px;
}
</style>