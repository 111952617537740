import axios from 'axios';
import GlobalLoadingAPI from '@/components/GlobalLoading/GlobalLoadingAPI'


let loading = GlobalLoadingAPI();
let requesting = 0;
let noLoadingUrlMaps = ['api/user/browse']


let instance = axios.create({
    baseURL: window.ApiDomain
})

instance.interceptors.request.use(function(config){
    if(noLoadingUrlMaps.includes(config.url) == false){
        requesting++;
        loading.el.style.display = "block"
    }
    
    return config
})

instance.interceptors.response.use(function(response){
    if(requesting > 0 && noLoadingUrlMaps.includes(response.config.url) == false){
        requesting--;
        if(requesting==0){
            loading.el.style.display = "none"
        }
    }
    
    return response
})

export default instance;