<template>
    <div class="exchange">
        <div class="panel1 flex">
            <div class="exchange-val">
                <input type="number" v-model="exchangeValue" placeholder="0.0">
                <p @click="redeemAll">{{ $t('Redeem all') }}</p>
            </div>
            <img src="@/assets/change_icon.svg" alt="">
            <div class="util">
                <img src="@/assets/usdt_icon.png" alt="">
                <span>USDT</span>
            </div>
        </div>
        <div class="panel2">
            <button class="red-btn" @click="exchange">{{ $t('Exchange') }}</button>
        </div>
        <div class="panel3">
            <p>{{ $t('exchange desc') }}</p>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { computed } from 'vue'
import { useStore } from '@/store'


export default {
    props:['exchangeAble'],
    methods: {
        async exchange(){
            if(parseFloat(this.exchangeValue)<=0){
                this.$toast(this.$t('Must be greater than 0'),1)
            }else{
                let response = await this.HTTPv1.post('api/user/exchange',{
                    amount: this.exchangeValue,
                    address: this.myAddress
                })
                if(response.data.code==200){
                    this.$toast(this.$t('Success'),3)
                }else{
                    this.$toast(response.data.msg,2)
                }
                this.$emit('updateData')
            }
        },
        redeemAll(){
            let val = parseFloat(this.exchangeAble)
            if(val>0){
                this.exchangeValue = val
            }
        }
    },
    setup(){
        let exchangeValue = ref('');
        let store = useStore();

        return {
            chainId: computed(()=>{
                return store.chainId
            }),
            myAddress: computed(()=>{
                return store.myAddress
            }),
            exchangeValue
        }
    }
}
</script>


<style scoped>
.panel3 {
    padding: 16px;
}
.panel3 p {
    color: #a1a1b3;
}
.panel2 {
    padding: 25px 0;
    border-bottom: 1px solid #eee;
}
.panel2 button{
    margin: 0 auto;
    
}
</style>
<style scoped>
@media screen and (min-width: 700px){
    .exchange-val p{
        font-size: 24px;
    }
    .panel1 input {
        font-size: 56px !important;
    }
    .panel1 {
        padding: 8px;
    }
    div.util img{
        width: 44px;
        height: 44px;
    }
    .util {
        font-size: 28px;
    }
}
.exchange {
    padding: 8px;
}
.panel1>img {
    width: 63px;
    height: 63px;
}
.panel1>div {
    width: calc(50% - 62px);
    flex: 1;
    padding: 0 15px;
}
.util {
    text-align: right;
}
.util img{
    width: 23px;
    height: 23px;
    vertical-align: middle;
    margin-right: 7px;
}
.panel1 {
    border: 1px solid #eee;
    margin: 8px;
    align-items: center;
    padding: 4px;
}
.panel1 input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 30px;
    font-weight: bold;
}
.exchange-val p{
    color: #8e5729;
}


</style>