import { defineStore } from 'pinia'

export const useStore = defineStore('store1',{
    state(){
        return {
            myAddress: '',
            chainId: '0x1',
            telegram: ''
        }
    }
})