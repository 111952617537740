<template>
    <div>
        <div class="pool-data block">
            <div class="title">{{ $t('Pool data') }}</div>
            <div class="data">
                <ul>
                    <li>
                        <div class="item-name">{{ $t('Total output') }}</div>
                        <div class="item-value blue">{{ data.poolData.totalOutput }} POL</div>
                    </li>
                    <li>
                        <div class="item-name">{{ $t('Valid node') }}</div>
                        <div class="item-value blue">{{ data.poolData.vailNode }}</div>
                    </li>
                    <li>
                        <div class="item-name">{{ $t('Participant') }}</div>
                        <div class="item-value">{{ data.poolData.participant }}</div>
                    </li>
                    <li>
                        <div class="item-name">{{ $t('User Revenue') }}</div>
                        <div class="item-value">{{ data.poolData.userRevenue }} USDT</div>
                    </li>
                    <li>
                        <div class="item-name">{{ $t('Wallet Address') }}</div>
                        <div class="item-value">{{ walletAddress }}</div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="block-desc">
            <h1>{{ $t('Mining') }}</h1>
            <p>{{ $t('Liquidity mining income') }}</p>
        </div>
        <div class="user-output block">
            <div class="title">{{ $t('User Output') }}</div>
            <div class="data">
                <div class="output-thead">
                    <div>{{ $t('Address') }}</div>
                    <div>{{ $t('Quatity') }}</div>
                </div>
                <TransitionGroup tag="ul" name="mining" class="user-output-ul">
                    <li v-for="item in userOutputListCurrentDisplayArray" :key="item.address">
                        <div class="item-name">{{ item.address }}</div>
                        <div class="item-value">{{ item.quantity }} USDT</div>
                    </li>
                </TransitionGroup>
            </div>
        </div>


        <!-- <div class="block-desc">
            <h1><a href="/certificates.jpg" target="_blank" style="text-decoration: none;color: black;">About us</a></h1>
        </div> -->
        <Collapse v-for="item in data.list" :title="item.title" :key="item.id" class="show">{{ item.content }}
        </Collapse>

        <div class="block-desc" style="margin-bottom: 10px">
            <h1>{{ $t('Audit report') }}</h1>
            <p>{{ $t('We have a secure audit report') }}</p>
        </div>

        <div class="audit flex">
            <img src="@/assets/audit/bottom_icon1.png" alt="">
            <img src="@/assets/audit/bottom_icon2.png" alt="">
            <img src="@/assets/audit/bottom_icon3.png" alt="">
        </div>


        <div class="block-desc">
            <h1>{{ $t('Partner') }}</h1>
            <p>{{ $t('We have a secure audit report') }}</p>
        </div>

        <div class="partner flex">
            <img src="@/assets/audit/bottom_icon4.png" alt="">
            <img src="@/assets/audit/bottom_icon5.png" alt="">
            <img src="@/assets/audit/bottom_icon6.png" alt="">
            <img src="@/assets/audit/bottom_icon7.png" alt="">
            <img src="@/assets/audit/bottom_icon8.png" alt="">
            <img src="@/assets/audit/bottom_icon9.png" alt="">
        </div>
    </div>
</template>

<script>
import { TransitionGroup, computed } from 'vue'
import Collapse from '@/components/Collapse/index.vue'
import { useStore } from '@/store'

export default {
    setup(){
        let store = useStore();

        return {
            walletAddress: computed(()=>{
                let tmp = store.myAddress.substr(10,24);
                return store.myAddress.replace(tmp,'...')
            }),
        }
    },
    components: { TransitionGroup, Collapse },
    data() {
        return {
            data: {
                poolData: {}
            },
            userOutputNextIndex: 7,
            userOutputListCurrentDisplayArray: []
        }
    },
    computed: {
        userOutputLength() {
            return this.data.userOutPut.length;
        }
    },
    async mounted() {
        let store = useStore();
        let response = (await this.HTTPv1('api/information/system'))
        if (response.status !== 200 || response.data.code !== 200) {
            return //请求失败
        }
        this.data = response.data.data;
        store.telegram = response.data.data.service;

        this.userOutputListCurrentDisplayArray = this.data.userOutPut.slice(0, 7); //当前展示区域的数组

        if (this.userOutputLength > 7) {
            setInterval(() => {
                // 如果是最后一个，则回到开头
                if (this.userOutputNextIndex >= this.userOutputLength) {
                    this.userOutputNextIndex = 0;
                }
                // 把下一个数据加到数据头部
                this.userOutputListCurrentDisplayArray.splice(0, 0, this.data.userOutPut[this.userOutputNextIndex])
                // 推出最后一个
                this.userOutputListCurrentDisplayArray.pop();
                this.userOutputNextIndex++;
            }, 1500)
        }
    }
}
</script>

<style scoped>
.audit,
.partner {
    justify-content: space-between;
    margin-bottom: 15px;
}

.audit img,
.partner img
{
    width: 28%;

}

.partner {
    flex-wrap: wrap;
}
.partner img {
    margin-top: 25px;
}
</style>

<style scoped>
@media screen and (min-width: 700px) {
    div.block .title {
        font-size: 36px;
        padding: 0 24px;
    }

    div.block .title::before {
        width: 7px;
        height: 36px;
        margin-top: 6px;
    }

    .block .data div.item-name,
    .block .data div.item-value {
        font-size: 24px;
    }

    .block .data li {
        margin: 24px 0 !important;
    }

    div.block-desc h1 {
        font-size: 36px;
    }

    div.block-desc p {
        font-size: 24px;
    }

    ul.user-output-ul {
        max-height: 276px;
    }

    .output-thead div {
        font-size: 28px;
    }
}

.output-thead {
    display: flex;
    display: -webkit-flex;
    margin-bottom: 8px;
}

.output-thead div {
    width: 50%;
}

.output-thead div:last-of-type {
    text-align: right;
}


.block-desc h1 {
    text-align: center;
    font-size: 18px;
    color: #000;
}

.block-desc p {
    color: #a1a1b3;
    text-align: center;
}

.user-output .data ul {
    position: relative;
    overflow: hidden;
}

.block {
    background-color: #fff;
    border-radius: 15px;
    padding-top: 24px;
    margin-bottom: 24px;
    box-shadow: 6px 12px 20px 0 rgb(0 0 0 / 5%);
}

.block .title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    padding: 0 15px;
}

.block .title::before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 4px;
    height: 20px;
    background: linear-gradient(1turn, rgba(90, 71, 217, .09), #71a8e0);
    border-radius: 4px;
}

.block .data {
    padding: 5px 15px;
}

.block .data li {
    display: flex;
    display: -webkit-flex;
    margin: 10px 0;
}

.user-output .data li {
    margin: 10px 0 !important;
}

.block .data li>div {
    width: 50%;
}

.block .data .item-name {
    color: #666;
}

.user-output .data .item-name {
    color: #2082fe;
}

.block .data .item-value {
    text-align: right;
    color: #222;
    font-size: 13px;
    font-weight: bold;
}

.block .data .item-value.blue {
    color: #2082fe;
}


.user-output-ul {
    max-height: 192px;
}

.mining-move,
.mining-enter-active,
.mining-leave-active {
    transition: .4s all ease;
}

.mining-enter-from {
    position: relative;
    opacity: 0;
    transform: translateY(-30px);
}

.mining-leave-to {
    position: relative;
    top: 30px;
    opacity: 0;
}
</style>