<template>
    <div class="lang-list">
        <ul>
            <li @click=" switchLang('en') ">English</li>
            <li @click=" switchLang('ms') ">Malaysia</li>
            <li @click=" switchLang('hi') ">हिन्दी</li>
            <li @click=" switchLang('vi') ">Tiếng Việt</li>
            <li @click=" switchLang('ko') ">한국인</li>
            <li @click=" switchLang('pt') ">Português</li>
            <li @click=" switchLang('ar') ">اللغة العربية</li>
            <li @click=" switchLang('ru') ">Русский язык</li>
        </ul>
    </div>
</template>

<script>
/**
 * hi： 印地语
 * ms：马来语
 * vi： 越南语
 * ko： 韩语
 * pt: 葡萄牙语
 * ar: 阿拉伯语
 * ru：俄语
 */

export default {
    methods: {
        switchLang(lang){
            localStorage.setItem('language',lang);
            location.reload();
        }
    }
}
</script>

<style scoped>
.lang-list {
    position: absolute;
    left: 85%;
    top: 5px;
    z-index: 66666;
}
ul {
    background-color: white;
    border-radius: 8px;
    border: 1px solid #ccc;
    max-height: 256px;
    overflow-y: scroll;
}
li{
    margin-top: 8px;
    padding: 7px;
    width: 120px;
}
li:not(:last-of-type){
    border-bottom: 1px solid #ccc;
}
</style>