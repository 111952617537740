<template>
  <EthUSDT />
</template>

<script>
import EthUSDT from "@/pages/EthereumUSDT/index.vue";

export default {
  name: "App",
  components: { EthUSDT },
};
</script>

<style>
html body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  /* background-color: #e0e8f3; */
  padding-bottom: 20px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html .container-fluid {
  padding: 0;
}
div.row {
  /* 重写bootstrap的样式 */
  margin: 0;
}

/**public style */
.w-auto {
  width: auto;
}
html ul {
  list-style: none;
  padding-left: 0;
  padding: 0;
  margin-bottom: 0;
} 
.flex {
  display: flex;
  display: -webkit-flex;
}
button {
  outline: none;
  border: none;
}
html p {
  margin: 0;
}
button:not(:disabled) {
  cursor: pointer;
}
button {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 35px;
  color: #fff;
  font-weight: 500;
  padding: 8px 20px;
  font-size: 13px;
  letter-spacing: 0.01em;
  border-radius: 4px;
  /* min-width: 160px; */
  /* border: 1px solid; */
  background: #8e5729;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  line-height: 24px;
  /* transition: .5s; */
  margin-top: 12px;
}
</style>
