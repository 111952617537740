import { createI18n } from 'vue-i18n'

let lang = {
    en: {
        'Receive_Voucher': 'Receive Voucher',
        'Join_the_node_and_start_mining': 'Join the node and start mining',
        'Receive': 'Receive',
        'Join BNB': 'Join POL',
        'MiningPool': 'MiningPool',
        'Account':'Account',
        'Team':'Team',
        'Pool data': 'Pool data',
        'Total output':'Total output',
        'Valid node': 'Valid node',
        'Participant':'Participant',
        'User Revenue': 'User Revenue',
        'Mining': 'Mining',
        'Liquidity mining income': 'Liquidity mining income',
        'User Output': 'User Output',
        'Address': 'Address',
        'Quatity': 'Quatity',
        'Help center': 'Help center',
        'Hope it helps you': 'Hope it helps you',
        'Audit report': 'Audit report',
        'We have a secure audit report': 'We have a secure audit report',
        'Partner': 'Partner',


        'My Account':'My Account',
        'Total output': 'Total output',
        'Wallet balance': 'Wallet balance',
        'System balance': 'System balance',
        'Exchangeable': 'Exchangeable',
        'Estimated income': 'Estimated income',
        'YOU ADDRESS': 'YOU ADDRESS',
        'Power': 'Power',
        'Exchange': 'Exchange',
        'Withdraw': 'Withdraw',
        'Redeem all': 'Redeem all',
        'Confirm': 'Confirm',
        'exchange desc': 'Convert the ETH coins you dug into USDT',
        'withdraw desc': 'Daily withdrawal 1, the withdrawn USDT will reach your wallet.',
        'level 1 Total output': 'level 1 Total output',
        'level 2 Total output': 'level 2 Total output',
        'level 3 Total output': 'level 3 Total output',
        'Team Revenue': 'Team Revenue',
        'My share link': 'My share link',
        'Copy': 'Copy',
        'share link desc': "Send your invitation link, friends join the node through your link, you will get 12% of the friend's income, and you can also get 6% of the income of friends' subordinates and 3% of the income of friends' referral members",
        'Success': 'Success',
        'Error': 'Error',
        'Must be greater than 0': 'Must be greater than 0',
        'Successful': 'Successful',
        'Invite': 'Invite',
        'Level': 'Level',
        'Wallet Address': 'Wallet Address',
        'share hashtable desc': 'Send your invitation link, friends join the node through your link, or friends invite other friends to join, you will receive generous token rewards:'
    },
    hi: {
        'Receive_Voucher': 'वोचर प्राप्त करें',
        'Join_the_node_and_start_mining': 'नोड में जोड़ें और मिनिंग प्रारंभ करें',
        'Receive': 'Receive',
        'Join BNB': 'बीन्बी में जोड़ें',
        'MiningPool': 'मिनिंगपुल',
        'Account':'खाता',
        'Team':'टीम',
        'Pool data': 'पूल डाटा',
        'Total output':'कुल आउटपुट',
        'Valid node': 'वैध नोड',
        'Participant':'सहभागी',
        'User Revenue': 'प्रयोक्ताओं की आयात',
        'Mining': 'मिनिंग',
        'Liquidity mining income': 'लाइक्विडिटी मिनिंग आयात',
        'User Output': 'उपयोक्ता आउटपुट',
        'Address': 'पता',
        'Quatity': 'क्वाटीटी',
        'Help center': 'मदद केंद्र',
        'Hope it helps you': 'आशा है कि यह आपकी मदद करेगा',
        'Audit report': 'आडिट रिपोर्ट',
        'We have a secure audit report': 'हमारे पास सुरक्षित ऑडिट रिपोर्ट है',
        'Partner': 'साझेदार',
        'We have a secure audit report': 'हमारे पास सुरक्षित ऑडिट रिपोर्ट है',

        'My Account':'मेरा खाता',
        'Total output': 'कुल आउटपुट',
        'Wallet balance': 'वालेट',
        'System balance': 'तंत्र',
        'Exchangeable': 'एक्सचेंजेबल',
        'Estimated income': 'अनुमानित आगत',
        'YOU ADDRESS': 'आप पताएँ',
        'Power': 'पावर',
        'Exchange': 'बदलें',
        'Withdraw': 'Penarikan',
        'Redeem all': 'Pilih semua',
        'Confirm': 'पुष्टि करें',
        'exchange desc': 'आप USDT में खड़े हुए ETH कोनियों को रूपांतरित करें',
        'withdraw desc': 'रोज़ी प्रतिलिपि हटाने के लिए 1, हटाया गया USDT तुम्हारे वालेट के पास पहुँचेगा.',
        'level 1 Total output': 'स्तर 1 कुल आउटपुट',
        'level 2 Total output': 'स्तर 2 कुल आउटपुट',
        'level 3 Total output': 'स्तर 3 कुल आउटपुट',
        'Team Revenue': 'टीम उत्पन्न',
        'My share link': 'मेरा साझा लिंक',
        'Copy': 'नकल करें',
        'share link desc': "अपने आमन्त्रण लिंक भेजें, दोस्तों नोड को अपने लिंक के द्वारा जोड़ते हैं, तुम दोस्त के आगत में से 12% प्राप्त होगे, और तुम दोस्तों के अधिकारों के आगत में से 6% भी प्",
        'Success': 'सफल',
        'Error': 'त्रुटि',
        'Must be greater than 0': '0 से बड़ा होना चाहिए',
        'Successful': 'सफलतापूर्वक',
        'Invite': 'आमन्त्रित करें',
        'Wallet Address': 'वालेट पता',
        'share hashtable desc': 'अपने आमन्त्रण लिंक भेजें, दोस्तों नोड के द्वारा अपने लिंक में शामिल होने के लिए, या दोस्तों ने अन्य दोस्तों को शामिल होने के ल'
    },
    ms: {
        'Receive_Voucher': 'Terima Voucher',
        'Join_the_node_and_start_mining': 'Sertai nod dan mula menggombak',
        'Receive': 'Terima',
        'Join BNB': 'Sertai POL',
        'MiningPool': 'MiningPool',
        'Account':'Akaun',
        'Team':'Pasukan',
        'Pool data': 'Data kumpulan',
        'Total output':'Jumlah output',
        'Valid node': 'Valid node',
        'Participant':'Peserta',
        'User Revenue': 'Daftar Pengguna',
        'Mining': 'Menyembah',
        'Liquidity mining income': 'Penghasilan perlombongan cair',
        'User Output': 'Output Pengguna',
        'Address': 'Alamat',
        'Quatity': 'Kualiti',
        'Help center': 'Pusat bantuan',
        'Hope it helps you': 'Saya harap ia membantu anda',
        'Audit report': 'Laporan Audit',
        'We have a secure audit report': 'Kami ada laporan audit yang selamat.',
        'Partner': 'Rakan',
        'We have a secure audit report': 'Kami ada laporan audit yang selamat.',


        'My Account':'Akaun saya',
        'Total output': 'Jumlah output',
        'Wallet balance': 'Imbangan dompet',
        'System balance': 'Imbangan sistem',
        'Exchangeable': 'Boleh Ganti',
        'Estimated income': 'Penghasilan dijangka',
        'YOU ADDRESS': 'YOU ADDRESS',
        'Power': 'Kuasa',
        'Exchange': 'Tukar',
        'Withdraw': 'Tarik',
        'Redeem all': 'Buang semula semua',
        'Confirm': 'Sahkan',
        'exchange desc': 'Tukar syiling ETH yang anda menggali ke USDT',
        'withdraw desc': 'Pengunduran sehari 1, USDT yang ditarik akan mencapai dompet anda.',
        'level 1 Total output': 'Jumlah output aras 1',
        'level 2 Total output': 'Jumlah output aras 2',
        'level 3 Total output': 'Jumlah output aras 3',
        'Team Revenue': 'Daftar Pasukan',
        'My share link': 'Pautan kongsi saya',
        'Copy': 'Salin',
        'share link desc': "Hantar pautan undangan anda, rakan-rakan bergabung dengan nod melalui pautan anda, anda akan mendapat 12% pendapatan rakan, dan anda juga boleh mendapat 6% pendapatan subordinat rakan-rakan dan 3% pendapatan anggota rujukan rakan-rakan",
        'Success': 'Berjaya',
        'Error': 'Ralat',
        'Must be greater than 0': 'Mesti lebih besar dari 0',
        'Successful': 'Berjaya',
        'Invite': 'Panggilan',
        'Wallet Address': 'Alamat Dompet',
        'share hashtable desc': 'Hantar pautan undangan anda, rakan-rakan bergabung dengan nod melalui pautan anda, atau rakan-rakan mengundang rakan-rakan lain untuk bergabung, anda akan menerima penghargaan token yang murah hati:'
    },
    vi: {
        'Receive_Voucher': 'Nhận chứng từ',
        'Join_the_node_and_start_mining': 'Tham gia nút và bắt đầu khai thác',
        'Receive': 'Tiếp nhận',
        'Join BNB': 'Nhập BND.',
        'MiningPool': 'Hồ bơi khai thác khoáng sản',
        'Account':'Tài khoản',
        'Team':'Đội ngũ',
        'Pool data': 'Dữ liệu hồ bơi',
        'Total output':'Tổng sản lượng',
        'Valid node': 'Các nút hợp lệ',
        'Participant':'Những người tham gia',
        'User Revenue': 'Doanh thu của người dùng',
        'Mining': 'Khai thác mỏ',
        'Liquidity mining income': 'Thu nhập từ khai thác thanh khoản',
        'User Output': 'Đầu ra của người dùng',
        'Address': 'Địa chỉ',
        'Quatity': 'Quatiti',
        'Help center': 'Trung tâm trợ giúp',
        'Hope it helps you': 'Hy vọng sẽ giúp bạn',
        'Audit report': 'Báo cáo kiểm toán',
        'We have a secure audit report': 'Chúng tôi có báo cáo kiểm toán an toàn',
        'Partner': 'Đối tác',
        'We have a secure audit report': 'Chúng tôi có báo cáo kiểm toán an toàn',


        'My Account':'Tài khoản của tôi',
        'Total output': 'Tổng sản lượng',
        'Wallet balance': 'Số dư ví',
        'System balance': 'Cân bằng hệ thống',
        'Exchangeable': 'Có thể trao đổi',
        'Estimated income': 'Ước tính doanh thu',
        'YOU ADDRESS': 'Địa chỉ của bạn',
        'Power': 'Động lực',
        'Exchange': 'Trao đổi',
        'Withdraw': 'Thu hồi',
        'Redeem all': 'Đổi lại tất cả',
        'Confirm': 'Khẳng định',
        'exchange desc': 'Chuyển đổi ETH Coin bạn đã đào sang USDT',
        'withdraw desc': 'Việc rút tiền hàng ngày 1, USDT đã rút lại sẽ tìm được ví.',
        'level 1 Total output': 'Tổng sản lượng cấp I',
        'level 2 Total output': 'Tổng sản lượng cấp 2',
        'level 3 Total output': 'Tổng sản lượng cấp 3',
        'Team Revenue': 'Thu nhập của đội',
        'My share link': 'Liên kết chia sẻ của tôi',
        'Copy': 'Sao chép',
        'share link desc': "Gửi liên kết mời của bạn, bạn bè tham gia các nút thông qua liên kết của bạn, bạn sẽ nhận được 12% lợi nhuận của bạn bè, cũng nhận được 6% lợi nhuận của bạn bè cấp dưới, 3% lợi nhuận của bạn bè giới thiệu thành viên",
        'Success': 'Thành công',
        'Error': 'Sai số',
        'Must be greater than 0': 'Phải lớn hơn 0',
        'Successful': 'Thành công',
        'Invite': 'Mời',
        'Wallet Address': 'Địa chỉ nhà',
        'share hashtable desc': 'Gởi liên kết thư mời, bạn bè tham gia lõi thông qua liên kết của bạn, hoặc bạn bè mời các bạn khác tham gia, bạn sẽ nhận phần thưởng tượng trưng hào phóng:'
    },
    ko: {
        'Receive_Voucher': '영수증',
        'Join_the_node_and_start_mining': '노드 가입 및 발굴 시작',
        'Receive': '받다',
        'Join BNB': 'POL 가입',
        'MiningPool': '채광지',
        'Account':'계정',
        'Team':'팀',
        'Pool data': '데이터 풀',
        'Total output':'총생산량',
        'Valid node': '유효 노드',
        'Participant':'참여자',
        'User Revenue': '사용자 수입',
        'Mining': '채광',
        'Liquidity mining income': '광업 수입의 유동성',
        'User Output': '사용자 출력',
        'Address': '주소',
        'Quatity': '수량',
        'Help center': '도움말 센터',
        'Hope it helps you': '도움이 됐으면 좋겠어요.',
        'Audit report': '감사 보고서',
        'We have a secure audit report': '우리는 안전한 감사 보고서를 가지고 있다',
        'Partner': '파트너',


        'My Account':'내 계좌',
        'Total output': '총생산량',
        'Wallet balance': '지갑 잔액',
        'System balance': '시스템 밸런스',
        'Exchangeable': '교환 가능',
        'Estimated income': '예상 수입',
        'YOU ADDRESS': '너의 주소',
        'Power': '권력',
        'Exchange': '교환',
        'Withdraw': '퇴각하다',
        'Redeem all': '모든 것을 되찾다',
        'Confirm': '실증하다',
        'exchange desc': '발굴한 ETH 코인을 USDT로 변환',
        'withdraw desc': '일일 인출 1, 인출한 USDT가 지갑에 도착합니다.',
        'level 1 Total output': '일급 총생산',
        'level 2 Total output': '2급 총산출',
        'level 3 Total output': '3급 총산출',
        'Team Revenue': '단체 수입',
        'My share link': '내 공유 링크',
        'Copy': '복사',
        'share link desc': "초대장 링크를 보내드립니다. 친구가 당신의 링크를 통해 노드에 가입하면 친구 수입의 12%를 얻을 수 있고, 친구 부하 수입의 6%와 친구 추천 구성원 수입의 3%를 얻을 수 있습니다.",
        'Success': '성공',
        'Error': '잘못',
        'Must be greater than 0': '0 이상이어야 합니다.',
        'Successful': '성공적',
        'Invite': '초대',
        'Wallet Address': '지갑 주소',
        'share hashtable desc': '당신의 초대 링크를 보내면 친구가 당신의 링크를 통해 노드에 가입하거나 친구가 다른 친구를 초대하면 아낌없는 코인 보상을 받을 수 있습니다.'
    },
    pt: {
        'Receive_Voucher': 'Receber Voucher',
        'Join_the_node_and_start_mining': 'Junte-se ao nó e comece a mineração',
        'Receive': 'Receber',
        'Join BNB': 'Juntar-se ao POL',
        'MiningPool': 'MiningPool',
        'Account':'Conta',
        'Team':'Equipa',
        'Pool data': 'Dados agrupados',
        'Total output':'Produção total',
        'Valid node': 'Nó válido',
        'Participant':'Participante',
        'User Revenue': 'Receitas dos Utilizadores',
        'Mining': 'Mineração',
        'Liquidity mining income': 'Rendimentos de mineração de liquidez',
        'User Output': 'Saída do Utilizador',
        'Address': 'Endereço',
        'Quatity': 'Quatity',
        'Help center': 'Central de Ajuda',
        'Hope it helps you': 'Espero que te ajude.',
        'Audit report': 'Relatório de auditoria',
        'We have a secure audit report': 'Temos um relatório de auditoria seguro.',
        'Partner': 'Parceiro',


        'My Account':'A Minha Conta',
        'Total output': 'Produção total',
        'Wallet balance': 'Saldo da carteira',
        'System balance': 'Balanço do sistema',
        'Exchangeable': 'Trocável',
        'Estimated income': 'Rendimento estimado',
        'YOU ADDRESS': 'ENDEREÇO',
        'Power': 'Potência',
        'Exchange': 'Troca',
        'Withdraw': 'Retirar',
        'Redeem all': 'Resgatar tudo',
        'Confirm': 'Confirmar',
        'exchange desc': 'Converta as moedas ETH que você cavou em USDT',
        'withdraw desc': 'Retirada diária 1, o USDT retirado chegará à sua carteira.',
        'level 1 Total output': 'nível 1 Produção total',
        'level 2 Total output': 'nível 2 Produção total',
        'level 3 Total output': 'nível 3 Produção total',
        'Team Revenue': 'Receitas da Equipa',
        'My share link': 'A minha ligação de partilha',
        'Copy': 'Copiar',
        'share link desc': "Envie seu link de convite, amigos entram no nó através do seu link, você receberá 12% da renda do amigo, e você também pode obter 6% da renda dos subordinados dos amigos e 3% da renda dos membros de referência dos amigos",
        'Success': 'Sucesso',
        'Error': 'Erro',
        'Must be greater than 0': 'Deve ser superior a 0',
        'Successful': 'Sucessivo',
        'Invite': 'Convidar',
        'Wallet Address': 'Endereço da Carteira',
        'share hashtable desc': 'Envie seu link de convite, amigos ingressam no nó através do seu link, ou amigos convidam outros amigos para participar, você receberá recompensas generosas de token:'
    },
    ar: {
        'Receive_Voucher': 'الحصول على قسيمة',
        'Join_the_node_and_start_mining': 'الانضمام إلى العقد والبدء في التعدين',
        'Receive': 'استلم',
        'Join BNB': 'بنك التسليف والادخار',
        'MiningPool': 'التعدين داخلي',
        'Account':'الحسابات',
        'Team':'فريق',
        'Pool data': 'تجمع البيانات',
        'Total output':'الناتج الإجمالي',
        'Valid node': 'عقدة فعالة',
        'Participant':'مشارك',
        'User Revenue': 'دخل المستخدم',
        'Mining': 'التعدين',
        'Liquidity mining income': 'سيولة عائدات التعدين',
        'User Output': 'الناتج المستخدم',
        'Address': 'العنوان',
        'Quatity': 'الكمية',
        'Help center': 'مركز المساعدة',
        'Hope it helps you': 'نأمل أن تساعدك',
        'Audit report': 'تقرير مراجعة الحسابات',
        'We have a secure audit report': 'لدينا تقرير مراجعة الحسابات الأمنية',
        'Partner': 'شريك',


        'My Account':'حسابي',
        'Total output': 'الناتج الإجمالي',
        'Wallet balance': 'محفظة التوازن',
        'System balance': 'نظام التوازن',
        'Exchangeable': 'صرف',
        'Estimated income': 'الإيرادات التقديرية',
        'YOU ADDRESS': 'عنوانك',
        'Power': 'قوة',
        'Exchange': 'مبادلة',
        'Withdraw': 'تراجع',
        'Redeem all': 'الخلاص من كل شيء',
        'Confirm': 'أكّد',
        'exchange desc': 'تحويل العملات المعدنية إلى usdt ETH',
        'withdraw desc': ' السحب اليومي 1 ، سحب USDT سوف تصل إلى محفظتك .  ',
        'level 1 Total output': ' الناتج الإجمالي من المستوى الأول  ',
        'level 2 Total output': ' الناتج الإجمالي الثانوي  ',
        'level 3 Total output': ' الناتج الإجمالي من المستوى الثالث  ',
        'Team Revenue': ' دخل الفريق  ',
        'My share link': ' بلدي تقاسم الرابط  ',
        'Copy': ' نسخ  ',
        'share link desc': " إرسال دعوات إلى الأصدقاء من خلال الرابط الخاص بك للانضمام إلى العقدة ، سوف تكسب 12 ٪ من دخل الأصدقاء ، يمكنك أيضا الحصول على 6 ٪ من دخل الأصدقاء والمرؤوسين و 3 ٪ من دخل الأعضاء الموصى بها من الأصدقاء  ",
        'Success': ' النجاح .  ',
        'Error': ' خطأ .  ',
        'Must be greater than 0': ' يجب أن يكون أكبر من 0  ',
        'Successful': ' ناجح  ',
        'Invite': 'دعوة',
        'Wallet Address': 'عنوان المحفظة',
        'share hashtable desc': ' إرسال دعوة إلى الرابط الخاص بك ، صديق ينضم إلى العقدة من خلال الرابط الخاص بك ، أو صديق يدعو صديق آخر للانضمام ، وسوف تحصل على مكافأة رمزية سخية : '
    },
    ru: {
        'Receive_Voucher': 'получать ордер',
        'Join_the_node_and_start_mining': 'Добавить узел и начать копание',
        'Receive': 'прием',
        'Join BNB': 'присоединиться к POL',
        'MiningPool': 'рудный бассейн',
        'Account':'счёт',
        'Team':'команда',
        'Pool data': 'пул данных',
        'Total output':'валовая продукция',
        'Valid node': 'эффективный узел',
        'Participant':'участник',
        'User Revenue': 'доход пользователя',
        'Mining': 'добывать',
        'Liquidity mining income': 'текучесть доходов от добычи полезных ископаемых',
        'User Output': 'выход пользователя',
        'Address': 'адрес',
        'Quatity': 'количество',
        'Help center': 'Центр помощи',
        'Hope it helps you': 'надежда тебе поможет',
        'Audit report': 'Доклад о ревизии',
        'We have a secure audit report': 'У нас есть отчет о проверке безопасности',
        'Partner': 'партнер',


        'My Account':'мой счёт',
        'Total output': 'валовая продукция',
        'Wallet balance': 'остаток бумажника',
        'System balance': 'системное равновесие',
        'Exchangeable': 'обмен',
        'Estimated income': 'расчетный доход',
        'YOU ADDRESS': 'твой адрес',
        'Power': 'власть',
        'Exchange': 'обмен',
        'Withdraw': 'отступать',
        'Redeem all': 'выкупать все',
        'Confirm': 'подтверждать',
        'exchange desc': 'преобразовать раскопки ETH в USDT',
        'withdraw desc': 'каждый день, чтобы получить деньги, USDT получит ваш бумажник.',
        'level 1 Total output': 'Общие мероприятия первого уровня',
        'level 2 Total output': 'Общие мероприятия второго уровня',
        'level 3 Total output': 'Общие мероприятия третьего уровня',
        'Team Revenue': 'коллективный доход',
        'My share link': 'Мои общие ссылки',
        'Copy': 'копировать',
        'share link desc': "Отправьте ссылку на приглашение, друзья через ссылку добавить узлы, вы получите 12% доходов друзей, вы также можете получить 6% доходов от подружек и 3% от рекомендуемого дохода участника",
        'Success': 'успех',
        'Error': 'ошибка',
        'Must be greater than 0': 'Должно быть больше 0',
        'Successful': 'успешный',
        'Invite': 'приглашать',
        'Wallet Address': 'адрес бумажника',
        'share hashtable desc': 'отправить приглашение по ссылке, друзья через вашу ссылку присоединиться к узлу, или друзья приглашают других друзей присоединиться к вам, вы получите щедрое денежное вознаграждение:'
    },
}

export const i18n = createI18n({
    locale: 'zh',
    fallbackLocale: 'en',
    messages: lang
})