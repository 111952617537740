<template>
    <HashRateTable v-if="hashRateTableShow" @updateShow=" hashRateTableShow = false" :teamData="teamData"/>
    <div>
        <div class="my-account block">
            <div class="title">{{ $t('My Account') }}</div>
            <div class="data">
                <ul>
                    <li>
                        <div class="item-name">{{ $t('Total output') }}</div>
                        <div class="item-value">{{totalOutput}} USDT</div>
                    </li>
                    <li>
                        <div class="item-name">{{ $t('Wallet balance') }}</div>
                        <div class="item-value">{{walletBalance}} USDT</div>
                    </li>
                    <li>
                        <div class="item-name">{{ $t('balance') }}</div>
                        <div class="item-value">{{systemBalance}} USDT</div>
                    </li>
                    <li>
                        <div class="item-name">{{ $t('Exchangeable') }}</div>
                        <div class="item-value">{{exchangeAble}} USDT</div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="panel2 row">
            <!-- <div class="card col-12 col-md-12 col-lg-4">
                <div class="card-part1 flex">
                    <div class="icon">
                        <img src="@/assets/logo-light-sm.png" alt="">
                    </div>
                    <div>
                        <div style="color: #777;">{{ $t('Estimated income') }}</div>
                        <div class="amount">0.0000000<span style="font-size:15px">ETH</span></div>
                    </div>
                </div>
                <div class="you-address">
                    <div style="text-align: center">{{ $t('YOU ADDRESS') }}</div>
                    <p class="address">{{myaddress}}</p>
                </div>
            </div> -->

            <div class="fan col-12 col-md-6 col-lg-4">
                <div class="fan-icon">
                    <img src="@/assets/fan1.png" alt="">
                </div>
                <div style="font-size: 20px;text-align:center;margin-top: 10px;">
                    <b style="color: #888;">{{ $t('Power') }}: </b>
                    <span style="font-weight: bold;">2000 GH/s</span>
                </div>
            </div>
            <div class="server col-12 col-md-6 col-lg-4">
                <div>
                    <img src="@/assets/server.png" alt="">
                </div>
                <div>
                    <button @click="hashRateTableShow = true">
                        <img src="@/assets/server-btn.png" alt="" style="width: 15px;vertical-align: middle;margin-right: 8px">
                        <span> Hash rate table</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="panel3">
            <!-- <div class="switch-tab">
                <ul class="flex">
                    <li @click=" currentComponent = 'Exchange'">{{ $t('Exchange') }}</li>
                    <li @click=" currentComponent = 'Withdraw'">{{ $t('Withdraw') }}</li>
                     <li @click=" currentComponent = 'Record'">Record</li>
                </ul>
            </div> -->
            <div class="my-account block">
                <div class="title">{{ $t(currentComponent) }}</div>
                <!-- <keep-alive>
                    <Exchange v-if="currentComponent == 'Exchange'" @updateData="requestData" :exchangeAble="data.data.exchangeAble" />
                </keep-alive> -->
                <keep-alive>
                    <Withdraw v-if="currentComponent == 'Withdraw'" @updateData="requestData" :exchangeAble="data.data.exchangeAble"/>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
import Exchange from './Exchang/index.vue'
import Record from './Record/index.vue'
import Withdraw from './Withdraw/index.vue'
import HashRateTable from '@/components/HashRateTable/HashRateTable.vue'
import { reactive,ref,computed } from 'vue'
import { useStore } from '@/store'

export default {
    setup(){
        let store = useStore();



        return {
            chainId: computed(()=>{
                return store.chainId
            }),
            myAddress: computed(()=>{
                return store.myAddress
            }),
            myaddress: computed(()=>{
                let tmp = store.myAddress.slice(-10);
                return store.myAddress.replace(tmp,'...')
            }),
        }
    },

    components:{Exchange,Record,Withdraw,HashRateTable},
    data(){
        return {
            teamData: {},
            data: {
                data:{},
            },
            totalOutput:'',
            walletBalance:'',
            systemBalance: '',
            exchangeAble: '',
            currentComponent: 'Withdraw',
            hashRateTableShow: false
        }
    },
    methods:{
        async requestData(){
            let response = (await this.HTTPv1('/api/information/user/'+this.myAddress))
            if(response.status !== 200) {
                return //请求失败
            }
            this.data = response.data;

            if(response.data.code == 500){
                this.data.data = {totalOutput: '0.0000', walletBalance: '0.0000', systemBalance: '0.0000', exchangeAble: '0.0000', inviteCode: ""}
            }

        },
        async requestTeamData(){
            let response = (await this.HTTPv1('api/information/team/'+this.myAddress))
            if (response.status !== 200) {
                return //请求失败
            }
            if(response.data.code==200){
                this.teamData = response.data.data;
            }
        }
    },
    async mounted(){
        this.requestData();
        this.requestTeamData();

        this.totalOutput = computed(()=>{
            let str = this.data.data.totalOutput+''
            if(str.length<8){
                str += str.indexOf('.') == -1 ? '.' : '';
                str = str.padEnd(8,'0')
            }
            return str
        })
        this.walletBalance = computed(()=>{
            let str = this.data.data.walletBalance+''
            if(str.length<8){
                str += str.indexOf('.') == -1 ? '.' : '';
                str = str.padEnd(8,'0')
            }
            return str
        })
        this.systemBalance = computed(()=>{
            let str = this.data.data.systemBalance+''
            if(str.length<8){
                str += str.indexOf('.') == -1 ? '.' : '';
                str = str.padEnd(8,'0')
            }
            return str
        })
        this.exchangeAble = computed(()=>{
            let str = this.data.data.exchangeAble+''
            if(str.length<8){
                str += str.indexOf('.') == -1 ? '.' : '';
                str = str.padEnd(8,'0')
            }
            return str
        })

    }
}
</script>




<style scoped>
.panel3 {
    margin-top: 20px;
}
@media screen and (min-width: 700px){
    .panel3 .switch-tab li {
        width: 220px;
        font-size: 28px;
        line-height: 68px !important;
    }
}
.panel3 .switch-tab ul {
    justify-content: space-between;
}
.panel3 .switch-tab li {
    background-color: rgba(237,206,182,.48);
    border-radius: 8px 8px 0 0;
    padding: 0 15px;
    line-height: 30px;
    text-align: center;
    width: 50%;
    margin: 0 5px;
}
.panel3 .switch-tab li:hover {
    background-color: #8e5729;
    color: #fff;
}

</style>

<style scoped>
.server {
    margin-top: 20px;
}
.server>div{
    text-align: center;
    margin-bottom: 10px;
}
.server>div:first-of-type>img {
    width: 50px;
}
.server button {
    background-color: transparent;
    color: #999;
    border: 1px solid #bbb;
    border-radius: 5px;
    padding: 5px 25px;
    margin: 0 auto;
    box-shadow: none;
}
.fan {
    padding: 22px 0;
    margin-top: 20px;
    border-bottom: 1px solid #eee;
}
.fan-icon {
    text-align: center;
}
.fan-icon img{
    width: 46px;
    animation: fan 1s linear infinite;
}
@keyframes fan {
    100% {
        transform: rotate(360deg);
    }
}
</style>

<style scoped>
@media screen and (min-width: 768px){
    div.block .title {
        font-size: 36px;
        padding: 0 24px;
    }
    div.block .title::before {
        width: 7px;
        height: 36px;
        margin-top: 6px;
    }
    .block .data div.item-name,
    .block .data div.item-value{
        font-size: 28px;
    }
    .card-part1 .amount {
        font-size: 23px;
    }
    .fan {
        border-right: 1px solid #eee;
        border-bottom: 0;
    }
}
.block {
    background-color: #fff;
    border-radius: 15px;
    padding-top: 24px;
    margin-bottom: 30px;
    box-shadow: 6px 12px 20px 0 rgb(0 0 0 / 5%);
}
.block .title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    padding: 0 15px;
}
.block .title::before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 4px;
    height: 20px;
    background: linear-gradient(1turn,rgba(90,71,217,.09),#71a8e0);
    border-radius: 4px;
}
.block .data {
    padding: 5px 15px;
}
.block .data li{
    display: flex;
    display: -webkit-flex;
    margin: 10px 0;
}
.block .data li>div{
    width: 50%;
}
.block .data .item-name{
    color: #666;
}
.block .data .item-value{
    text-align: right;
    color: #222;
    font-size: 16px;
    font-weight: bold;
}
.block .data .item-value.blue{
    color:#2082fe;
}


.panel2 {
    background-color: #fff;
    border-radius: 15px;
    padding: 16px;
    align-items: center;
}
.card {
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 15px 30px;
}
.card-part1 .icon {
    line-height: 70px;
    margin-right: 30px;
}
.card-part1 img{
    width: 24px;
}
.card-part1 .amount {
    font-size: 23px;
    font-weight: bold;
    line-height: 50px;
}

.you-address {
    color: #888;
}
.address {
    font-weight: bold;
    font-size: small;
    color: #222;
    margin-top: 10px;
}
</style>