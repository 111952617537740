<template>
    <div class="hash-table-wrap">
        <div class="hash-rate">
            <div class="title">{{ $t('Level') }}</div>
            <ul class="share-table">
                <li class="thead">
                    <div>Level</div>
                    <div style="border-right: 1px solid #bbb;">Interval</div>
                    <div>Rebate</div>
                </li>
                <li v-for="item in teamData.levelTables" :key="item[0]">
                    <div>{{item.level}}</div>
                    <div>{{item.interval}}</div>
                    <div>{{ item.rebate }}</div>
                </li>
            </ul>
            <div class="btn-wrap">
                <button @click="close">Confirm</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['teamData'],
    emits: ['updateShow'],
    data(){
        return {
            leaveStartingValue: ''
        }
    },
    mounted(){
        let box = document.querySelector('.hash-rate');
        let parent = box.parentElement;
        let boxTop = (parent.offsetHeight / 2) - (box.offsetHeight / 2)
        this.leaveStartingValue = 'translate(0,'+boxTop+'px)'

        box.animate({
            transform: [this.leaveStartingValue]
        },{
            duration: 250,
            fill: "forwards"
        });
    },
    methods:{
        close(){
            let box = document.querySelector('.hash-rate');

            let animate = box.animate({
                transform: [this.leaveStartingValue,'translate(0,0)']
            },{
                duration: 250,
                fill: "forwards"
            });
            animate.onfinish = ()=>{
                this.$emit('updateShow')
            }
        }
    }
}
</script>


<style scoped>
.share-table {
    border: 1px solid #bbb;
    margin: 10px;
    color: #999;
}
.share-table li{
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
}
.share-table li div{
    display: flex;
    flex: 1;
    text-align: center;
    padding: 8px;
    width: 33.3%;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
    align-content: center;
    word-break: break-all;
}
.share-table li>div:not(:last-of-type){
    border-right: 1px solid #bbb;
}


.share-table .thead div{
    line-height: 18px;
    font-weight: bold;
    background-color: #f2f2f2;
}
.share-table li:not(.thead) div{
    min-height: 48px;
}
.share-table li:not(.thead){
    border-top: 1px solid #bbb;
}
</style>
<style scoped>
.hash-table-wrap {
    position: fixed;
    background-color: rgba(0,0,0,.3);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 666666;
}
.hash-rate {
    margin: 0 5%;
    width: 90%;
    background-color: #fff;
    box-shadow: 6px 12px 20px 0 rgb(0 0 0 / 5%);
    border-radius: 12px;
    padding: 16px 0;
}
.btn-wrap button{
    margin: 0 auto;
}
.title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    padding: 0 15px;
}
.title::before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 4px;
    height: 20px;
    background: linear-gradient(1turn,rgba(90,71,217,.09),#71a8e0);
    border-radius: 4px;
}
</style>