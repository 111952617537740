<template>
    <div class="main-mid">
        <div class="switch-tab" style="margin-bottom: 10px">
            <ul>
                <li @click=" currentContentComponent = li.key " :class=" {active:currentContentComponent===li.key} " v-for="(li,index) in contentComponents" :key="li.onlyKey">{{li.label}}</li>
            </ul>
        </div>
        <component :key="currentContentComponent" v-if="currentContentComponent!=''" :is="currentContentComponent"></component>
    </div>
</template>

<script>
import MiningPool from './MiningPool/index.vue'
import Account from './Account/index.vue'
import Team from './Team/index.vue'

export default {
    data(){
        return {
            contentComponents: [],
            currentContentComponent: ''
        }
    },
    mounted(){
        this.contentComponents = [{
            onlyKey: 'qwe',
            key: 'MiningPool',
            label: this.$t('MiningPool')
        },{
            onlyKey: 'asd',
            key: 'Account',
            label: this.$t('Account')
        },{
            onlyKey: 'zxc',
            key: 'Team',
            label: this.$t('Team')
        }]
        this.currentContentComponent = this.contentComponents[0].key
    },
    components:{MiningPool,Account,Team},
}
</script>

<style scoped>
@media screen and (min-width: 700px){
    .main-mid {
        margin: 0 16px;
    }
}
.main-mid {
    margin-top: 24px;
    padding: 0 15px;
}
.switch-tab ul{
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    min-height: 31.5px;
}
.switch-tab li{
    width: 33.3%;
    text-align: center;
    font-weight: bold;
    color: #1c65c9;
    /* transition: .3s; */
}
.switch-tab .active {
    color: #282c3c;
    font-size: 16.6px;
}
.switch-tab .active::after {
    content: "";
    display: block;
    width: 50px;
    height: 4px;
    background-color: #8e5729;
    margin: 5px auto 0;
}
</style>