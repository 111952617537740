import { createVNode, render } from 'vue'
import GlobalLoading from './GlobalLoading.vue'

/**
 * 全局只有一个loading元素，需要用loading的地方，就引入这个API，得到的都是同一个vue组件实例
 * 控制app.el的css来展示或隐藏
 */

let app = createVNode(GlobalLoading,{})
render(app,document.body)
app.el.style.display = "none"


export default function(){

    return app
}