<template>
    
    <div class="container-fluid">
        <TopContainer />
        <MainContent />
        <teleport to="body">
            <!-- <Telegram :url="telegram" v-if="telegram"/> -->
            <Chat :url="telegram" v-if="telegram" />
            <div class="pop-list-wrap">
                <div class="pop-list flex">
                    
                </div>
            </div>
            <Notice v-if="showNotice" :title="noticeTitle" :msg="noticeContent" @close="closeNotice"/>
            <UserMessage v-if="userMessage.show" :title="userMessage.title" :msg="userMessage.msg" @read="userMessageRead"/>
        </teleport>
    </div>
</template>

<script>
import { computed } from 'vue'
import TopContainer from './TopContainer/index.vue'
import MainContent from './MainContent/index.vue'
import Telegram from '@/components/Telegram/index.vue'
import Notice from '@/components/Notice/Notice.vue'
import Chat from '@/components/Chat/Chat.vue'
import { useStore } from '@/store'
import UserMessage from '@/components/UserMessage/UserMessage.vue'

export default {
    setup(){
        let store = useStore();

        return {
            telegram: computed(()=>{
                return store.telegram;
            }),
            myAddress: computed(()=>{
                return store.myAddress;
            }),
        }
    },
    data(){
        return {
            showNotice: false,
            noticeTitle: '我是标题',
            noticeContent: '我是内容',
            userMessage: {
                show: false,
                title: '',
                msg: '',
                id: ''
            }
        }
    },
    async mounted(){
        let res = (await this.HTTPv1.get('/api/notice/sysNotice')).data;
        if(res.data) {
            this.showNotice = true;
            this.noticeTitle = res.data.title;
            this.noticeContent = res.data.subject;
        }else{
            this.getUserMessage();
        }
    },
    methods: {
        closeNotice(){
            this.showNotice = false;
            this.getUserMessage();
        },
        async userMessageRead(){
            await this.HTTPv1.put('/api/notice/readMessage/'+this.userMessage.id)
            this.userMessage.show = false;
        },
        async getUserMessage(){
            let res = (await this.HTTPv1.get('/api/notice/userMessage/'+this.myAddress)).data;
            if(res.data) {
                this.userMessage.show = true;
                this.userMessage.title = res.data.title;
                this.userMessage.msg = res.data.subject;
                this.userMessage.id = res.data.id;
            }
        }
    },
    components: {
    TopContainer,
    MainContent,
    Telegram,
    Chat,
    Notice,
    UserMessage
}
}
</script>

<style>
.pop-list-wrap {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 666666666;
}
.pop-list {
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
}
</style>