<template>
    <div class="list">
        <ul class="thead">
            <li>Time</li>
            <li>Quantity</li>
        </ul>
        <div v-if="data.length === 0" class="nodata">
            <img src="@/assets/nodata_icon.svg" alt="">
            <p>No Data</p>
        </div>
        <div v-else>
            <ul v-for="item in data" :key="item.time">
                <li>{{item.time}}</li>
                <li>{{item.quantity}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    setup(){
        let data = reactive([]);
        return {data}
    }
}
</script>

<style>
.list ul{
    display: flex;
    display: -webkit-flex;
    padding: 0 16px;
}
.list li{
    flex: 1;
    padding: 0 15px;
}
.list ul li:not(:first-of-type){
    text-align: right;
}
.nodata {
    text-align: center;
}
</style>