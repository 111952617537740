<template>
    <div class="global-loading">
        <div class="wrap">
            <img src="@/assets/loading.png" alt="" />
            <p>Loading...</p>
        </div>
    </div>
</template>

<script>
/**
 * 目前此组件仅用于GlobalLoadingAPI中使用
 */
export default {
}
</script>

<style scoped>
.global-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.6);
    z-index: 6666;
}
.wrap {
    position: absolute;
    top: calc(50% - 40px);
    width: 85px;
    height: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}
p {
    color: #ccc
}
img {
    animation: rotate 2s linear infinite;
}
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
</style>