<!-- 全局唯一存在，全局右下角的跳转telegram联系方式的链接 -->
<template>
    <teleport to="body">
        <div class="chat-jump" ref="chat">
            <a :href="url" target="_self">
                <div class="img-wrap">
                    <img src="@/assets/service.jpg" alt="">
                </div>
            </a>
        </div>
    </teleport>
</template>

<script>
/**
 * 目前使用此组件的页面路径：
 * pages/EthereumUSDT/MainContent/MiningPool （因为所需跳转的链接，来自这个页面的接口）
 */
import Drag from '@/utils/VueDraggable'
import { ref, onMounted,onUnmounted } from 'vue'
export default {
    props:['url'],
    setup(){
        let chat = ref(null)
        let drag = null;

        onMounted(()=>{
            drag = new Drag(chat.value,()=>{
                //单击事件
            },40,40)
        })
        onUnmounted(()=>{
            drag.destory();
        })
        return {chat}
    }
}
</script>

<style scoped>
.chat-jump {
    /* position: fixed;
    right: 15px;
    bottom: 15px; */
    left: calc(100% - 50px);
    top: calc(50% - 20px);
    border-radius: 50%;
    background-color: #3126cb;
    box-shadow: 2px 2px 3px #999;
    z-index: 1997;
    cursor: pointer;
}
a{
    font-size: 0;
}
/* .img-wrap {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    text-align: center;
    margin: 15px auto;
    font-size: 0;
}
img {
    width: 80%;
    height: 80%;
    margin-top: 2px;
} */
.img-wrap {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 50%;
    text-align: center;
    font-size: 0;
    overflow: hidden;
}
img {
    width: 40px;
    height: 40px;
}
</style>