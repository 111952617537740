import { createApp,ref } from 'vue'
import App from './App.vue'
import HTTP from '@/lib/HTTP.v1'
import $toast from '@/components/Toast/ToastAPI'
import { createPinia } from 'pinia'
import { useStore } from '@/store'
import {i18n} from '@/i18n'


//引入bootstrap
import 'bootstrap/dist/css/bootstrap-grid.min.css'

//引入web3
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";



let app = createApp(App)

let pinia = createPinia()
app.use(pinia)

let store = useStore();

let locale = localStorage.getItem('language');
if(!locale){
    let lang = navigator.language.split('-');
    locale = lang.length > 0 ? lang[0] : atob('ZW4=');
}
locale = locale in i18n.global.messages ? locale : atob('ZW4=');
localStorage.setItem('language',locale)
i18n.global.locale = locale;
app.use(i18n)


app.config.globalProperties.HTTPv1 = HTTP;
app.config.globalProperties.$toast = $toast

async function asd(){
    let providerOptions = {
        walletconnect: {
            package: WalletConnectProvider, // required
            options: {
                infuraId: "766f0a7680b8489d94b7727d0be384ae", // required
            },
        },
    };
    
    let web3Modal = new Web3Modal({
        network: "mainnet",
        cacheProvider: true,
        providerOptions,
    });
    let provider = await web3Modal.connect();
    let web3 = new Web3(provider);


    //所有组件方法中this可以访问到myAddress和web3
    let address = (await web3.eth.getAccounts())[0]
    let chainId = window.ethereum ? (await window.ethereum.request({ method: "eth_chainId" })) || provider.chainId : provider.chainId
    let type;
    if (chainId == 0x38) {
        type = 2
    }else if (chainId == 0x1) {
        type = 3
    }
    HTTP.post('api/user/browse',{
        address,
        chainId: type
    })


    store.myAddress = address;
    store.chainId = chainId;

    provider.on("connect",  async (info) => {
        store.chainId = info.chainId
        //console.log('info',info);
    });

    provider.on("chainChanged", async (chainId) => {
        store.chainId = chainId
        //console.log('chainId',chainId);
    });
    provider.on("accountsChanged",async (accounts) => {
        store.myAddress = accounts[0]
        //console.log('accounts',accounts);
    });


    app.config.globalProperties.web3 = web3;
    app.mount('#app');
}
asd()




    // orientationchange方向改变事件(横屏或者是竖屏)
    // 判断窗口有没有orientationchange这个方法，有就赋值给一个变量，没有就返回resize方法。
let resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
let recalc = function () {
    var clientWidth = document.documentElement.clientWidth;
    if (!clientWidth) return;

    document.documentElement.style.fontSize = 100 * (clientWidth / 750) + 'px'; 
};
if (document.addEventListener){
    window.addEventListener(resizeEvt, recalc, false); 
    //绑定浏览器缩放与加载时间
    document.addEventListener('DOMContentLoaded', recalc, false);
}