<!-- 全局唯一存在，全局右下角的跳转telegram联系方式的链接 -->
<template>
    <teleport to="body">
        <div class="telegram">
            <a :href="url" target="_blank">
                <div class="img-wrap">
                    <img src="@/assets/telegram.png" alt="">
                </div>
            </a>
        </div>
    </teleport>
</template>

<script>
/**
 * 目前使用此组件的页面路径：
 * pages/EthereumUSDT/MainContent/MiningPool （因为所需跳转的链接，来自这个页面的接口）
 */
export default {
    props:['url'],
    methods:{
        open(){
            this.url ? window.open(this.url) : null;
        }
    }
}
</script>

<style scoped>
.telegram {
    position: fixed;
    right: 15px;
    bottom: 15px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 50%;
    background-color: #3126cb;
    box-shadow: 2px 2px 3px #999;
    z-index: 1997;
    cursor: pointer;
}
.img-wrap {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    text-align: center;
    margin: 15px auto;
    line-height: 42px;
    padding-right: 2px;
}
img {
    width: 80%;
    height: 80%;
}
</style>