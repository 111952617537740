<template>
    <div class="record">
        <ul class="switch flex">
            <li :class=" {active: currentComponent==='Exchange'} " @click="currentComponent = 'Exchange'">Exchange</li>
            <li :class=" {active: currentComponent==='Withdraw'} " @click="currentComponent = 'Withdraw'">Withdraw</li>
            <li :class=" {active: currentComponent==='Mining'} " @click="currentComponent = 'Mining'">Mining</li>
        </ul>
        <div style="margin: 48px 8px 0;padding-bottom: 15px;">
            <component :is="currentComponent"/>
        </div>
    </div>
</template>

<script>
import Exchange from './Exchange/index.vue'
import Withdraw from './Withdraw/index.vue'
import Mining from './Mining/index.vue'

import {ref} from 'vue'

export default {
    components: {
        Exchange,Withdraw,Mining
    },
    setup(){
        let currentComponent = ref('Exchange');
        return {currentComponent}
    }
}
</script>

<style>
@media screen and (min-width: 700px){
    .switch {
        width: 615px !important;
    }
    .switch li{
        width: 205px !important;
        font-size: 28px;
        line-height: 60px !important;
    }
}
.record {
    margin: 16px 0;
}
.switch {
    width: 285px;
    border: 1px solid #8e5729;
    border-radius: 8px;
    margin: 16px auto;
    overflow: hidden;
}
.switch li{
    width: 95px;
    padding: 0 16px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
}
.switch li:not(:last-of-type){
    border-right: 1px solid #8e5729;
}
.switch .active {
    background-color: #8e5729;
    color: white;
}
</style>